export interface ILoggedUser {
  givenName: string;
  username: string;
  email: string;
  roles: ROLE[];
}

export interface ILoginUser {
  username: string;
  password: string;
}

export enum ROLE {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_REVIEWER = 'ROLE_REVIEWER',
  ROLE_APPROVER = 'ROLE_APPROVER',
}
