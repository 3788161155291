import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getUser, isLoggedIn } from '../../modules/security/services/auth';
import { getRouteByRole } from './utils';

function PrivateRoute({ children }: { children: JSX.Element }) {
  const location = useLocation();

  const user = getUser();

  const userHasPermission = location.pathname.indexOf(getRouteByRole(user?.roles[0]));

  if (!isLoggedIn()) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (userHasPermission === -1) {
    return <Navigate to={getRouteByRole(user.roles[0])} state={{ from: location }} />;
  }

  return children;
}

export default PrivateRoute;
