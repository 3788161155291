import React, { useEffect } from 'react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { message } from 'antd';

import './App.css';
import { types } from './core/stores/global';
import AppRoutes from './core/routes/Routes';

import LoadingScreen from './commons/loading';
import useGlobalContext from './core/stores/global/hook';
import useRefreshUser from './core/hook/useRefreshUser';

const appCache = createCache({
  key: 'evericore-cuba',
});

function App() {
  const globalContext = useGlobalContext();
  const refreshUser = useRefreshUser();

  useEffect(() => {
    refreshUser();
  }, []);

  useEffect(() => {
    if (globalContext.state.error) {
      message.error(globalContext.state.error);
      globalContext.dispatch({ type: types.CLEAR_ERROR });
    }
  }, [globalContext.state.error]);

  return (
    <CacheProvider value={appCache}>
      <AppRoutes />
      <LoadingScreen />
    </CacheProvider>
  );
}

export default App;
