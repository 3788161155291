import axios, { Axios, AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

class ApiService {
  axiosInstance: Axios;

  constructor(url: string) {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}${url}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Accept-Language': 'en',
      },
      withCredentials: true,
    });
  }

  onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  onError = (error: AxiosError<{ message: string; reason?: string }>) => {
    const message =
      error?.response?.data?.reason ?? error?.response?.data?.message ?? error.message;
    return Promise.reject(message);
  };

  query(resource: string, params: Record<string, unknown>) {
    return this.axiosInstance.get(resource, params).catch(this.onError);
  }

  get(resource: string) {
    return this.axiosInstance.get(`${resource}`).catch(this.onError);
  }

  post<U>(resource: string, body: Record<string, unknown>, config?: AxiosRequestConfig) {
    return this.axiosInstance.post<U>(`${resource}`, body, config).catch(this.onError);
  }

  update(resource: string, slug = '', body: Record<string, unknown>) {
    return this.axiosInstance.put(`${resource}/${slug}`, body).catch(this.onError);
  }

  put(resource: string, body: Record<string, unknown>) {
    return this.axiosInstance.put(`${resource}`, body).catch(this.onError);
  }

  delete(resource: string) {
    return this.axiosInstance.delete(resource).catch(this.onError);
  }
}

export default ApiService;
