import React from 'react';
import { Layout, MenuProps } from 'antd';
import MainHeader from './Header';
import MainSidenav from './Sidenav';

const { Content } = Layout;

interface Props {
  children: React.ReactNode;
  sideMenuItems: MenuProps['items'];
  onClickSideMenu: MenuProps['onClick'];
  sideMenuDefaultSelecteKey: [string];
}

const MainLayout: React.FC<Props> = ({
  children,
  sideMenuItems,
  onClickSideMenu,
  sideMenuDefaultSelecteKey,
}) => (
  <Layout style={{ height: '100vh' }}>
    <MainHeader />
    <Layout>
      <MainSidenav
        items={sideMenuItems}
        onClick={onClickSideMenu}
        defaultSelecteKey={sideMenuDefaultSelecteKey}
      />
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  </Layout>
);

export default MainLayout;
