import styled from '@emotion/styled';
import { Col, Layout, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import AppLogo from '../icons/logo';
import UserAvatar from '../user_avatar/UserAvatar';
import { types } from '../../core/stores/global';
import loginService from '../../modules/security/services/login';
import useGlobalContext from '../../core/stores/global/hook';

const { Header } = Layout;

const HeaderComp = styled(Header)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function MainHeader() {
  const navigate = useNavigate();
  const globalContext = useGlobalContext();
  const handleLogout = () => {
    globalContext.dispatch({ type: types.LOADING, payload: true });
    loginService
      .logout()
      .then(() => {
        globalContext.dispatch({ type: types.LOGOUT });
        setTimeout(() => {
          globalContext.dispatch({ type: types.LOADING, payload: false });
          navigate('/');
        }, 500);
      })
      .catch(() => globalContext.dispatch({ type: types.LOADING, payload: false }));
  };
  return (
    <HeaderComp>
      <AppLogo height={20} width={20} />
      <Row justify="center" align="middle" gutter={[40, 0]}>
        <Col flex={1} style={{ height: '100%', display: 'flex' }}>
          {/* <FlexContainer>
            <Badge count={5} size="small">
              <BellOutlined style={{ color: 'white', fontSize: 20 }} />
            </Badge>
          </FlexContainer> */}
        </Col>
        <Col flex={3}>
          <UserAvatar onLogout={handleLogout} />
        </Col>
      </Row>
    </HeaderComp>
  );
}

export default MainHeader;
