import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateModulesIndex from '../../modules';
import PrivateRoute from './PrivateRoute';

const Login = React.lazy(() => import('../../modules/security'));
const AdminDashboard = React.lazy(() => import('../../modules/admin'));
const StaffDashboard = React.lazy(() => import('../../modules/staff'));
const IdentityRequestContainer = React.lazy(
  () => import('../../modules/staff/containers/identity_request')
);

const ReviewIdentityRequestContainer = React.lazy(
  () => import('../../modules/staff/containers/review_identity_request')
);

const UsersContainer = React.lazy(() => import('../../modules/admin/containers/UsersContainer'));
const CustomerAccountsContainer = React.lazy(
  () => import('../../modules/admin/containers/CustomerAccountsContainer')
);

function AppRoutes() {
  return (
    <React.Suspense fallback="...Loading">
      <Routes>
        <Route path="login" element={<Login />} />
        {/* Private Route */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <PrivateModulesIndex />
            </PrivateRoute>
          }
        >
          <Route
            path="admin"
            element={
              <PrivateRoute>
                <AdminDashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<CustomerAccountsContainer />} />
            <Route path="users" element={<UsersContainer />} />
            <Route path="customers" element={<CustomerAccountsContainer />} />
          </Route>
          <Route
            path="staff"
            element={
              <PrivateRoute>
                <StaffDashboard />
              </PrivateRoute>
            }
          >
            <Route index element={<IdentityRequestContainer />} />
            <Route path="identity_request" element={<IdentityRequestContainer />} />
            <Route path="identity_request/:id" element={<ReviewIdentityRequestContainer />} />
          </Route>
        </Route>
        <Route path="*" element={<>Not Found</>} />
      </Routes>
    </React.Suspense>
  );
}

export default AppRoutes;
