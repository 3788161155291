import React from 'react';
import { Layout, Menu, MenuProps } from 'antd';

const { Sider } = Layout;

interface Props {
  items: MenuProps['items'];
  onClick: MenuProps['onClick'];
  defaultSelecteKey: MenuProps['defaultSelectedKeys'];
}

const MainSidenav: React.FC<Props> = ({ items, defaultSelecteKey, onClick }) => {
  return (
    <Sider collapsed width={200} className="site-layout-background">
      <Menu
        mode="inline"
        inlineCollapsed={true}
        selectedKeys={defaultSelecteKey}
        style={{ height: '100%' }}
        items={items}
        onClick={onClick}
      />
    </Sider>
  );
};

export default MainSidenav;
