import { handleLogin, logout } from '../../../modules/security/services/auth';
import { TAction } from './actions';
import { IGlobalState } from './initialState';
import * as types from './types';

const reducer = (state: IGlobalState, action: TAction): IGlobalState => {
  const { type } = action;
  switch (type) {
    case types.MAKE_REQUEST:
      return { ...state, loading: true };
    case types.LOGIN_SUCCESS:
      handleLogin(action?.payload);
      return {
        ...state,
        loading: false,
        user: action?.payload,
        isAuthenticated: true,
      };

    case types.REQUEST_ERROR:
      return {
        ...state,
        loading: false,
        error: action?.payload,
        isAuthenticated: false,
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        error: undefined,
      };
    case types.LOGOUT:
      logout();
      return { ...state, isAuthenticated: false };
    case types.LOADING:
      return { ...state, loading: action?.payload };
    case types.SET_MENU_ITEMS:
      return { ...state, menuItems: action?.payload };
    case types.SET_SELECTED_MENU_KEYS:
      return { ...state, selectedMenuKeys: action?.payload };
    default:
      return state;
  }
};
export default reducer;
