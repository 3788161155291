import React from 'react';
import styled from '@emotion/styled';
import { Typography } from 'antd';

import Logo from '../../assets/svg/logo_white.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const { Title } = Typography;

interface Props {
  onClick?: () => void;
  width: number;
  height: number;
}

const AppLogo: React.FC<Props> = ({ onClick, width, height }) => (
  <Container onClick={onClick}>
    <img width={width ?? 50} height={height ?? 50} src={Logo} alt="eVericore Logo" />
    <Title level={4} style={{ color: 'white', margin: '0 0 0 10px' }}>
      eVericore
    </Title>
  </Container>
);

export default AppLogo;
