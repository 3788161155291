import { MenuProps } from 'antd';
import { ILoggedUser } from '../../types/user';

export interface IGlobalState {
  isAuthenticated: boolean;
  loading: boolean;
  user?: ILoggedUser | undefined;
  error: string | undefined;
  selectedMenuKeys: [string];
  menuItems: MenuProps['items'];
}

const initialState: IGlobalState = {
  isAuthenticated: false,
  loading: false,
  user: undefined,
  error: undefined,
  selectedMenuKeys: [''],
  menuItems: [],
};

export default initialState;
