import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Main from '../commons/layout/Main';
import { types } from '../core/stores/global';
import useGlobalContext from '../core/stores/global/hook';

const PrivateModulesIndex = () => {
  const globalContext = useGlobalContext();
  const navigate = useNavigate();

  return (
    <Main
      sideMenuDefaultSelecteKey={globalContext.state.selectedMenuKeys}
      sideMenuItems={globalContext.state.menuItems}
      onClickSideMenu={(e) => {
        globalContext.dispatch({
          type: types.SET_SELECTED_MENU_KEYS,
          payload: [e.key],
        });
        navigate(e.key);
      }}
    >
      <Outlet />
    </Main>
  );
};

export default PrivateModulesIndex;
