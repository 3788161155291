import { UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu, MenuProps, Col, Row, Typography } from 'antd';
import React from 'react';
import useGlobalContext from '../../core/stores/global/hook';

const { Text } = Typography;

interface Props {
  onLogout: () => void;
}

const menuItems = [{ key: 'logout', label: 'Logout' }];

const UserAvatar: React.FC<Props> = ({ onLogout }) => {
  const globalContext = useGlobalContext();

  const onClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'logout') {
      onLogout();
    }
  };

  const menu = <Menu items={menuItems} onClick={onClick} />;

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Row gutter={[10, 0]} style={{ cursor: 'pointer' }}>
        <Col flex={1}>
          <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
        </Col>
        <Col flex={2}>
          <Text style={{ color: 'white' }}>
            {globalContext?.state?.user?.username ?? 'No Name'}
          </Text>
        </Col>
      </Row>
    </Dropdown>
  );
};

export default UserAvatar;
