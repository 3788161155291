import React from 'react';
import { getUser, isLoggedIn } from '../../modules/security/services/auth';
import { types } from '../stores/global';
import useGlobalContext from '../stores/global/hook';

const useRefreshUser = () => {
  const globalContext = useGlobalContext();
  return () => {
    if (isLoggedIn()) {
      globalContext.dispatch({ type: types.LOGIN_SUCCESS, payload: getUser() });
    }
  };
};

export default useRefreshUser;
