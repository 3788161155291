import { v4 as uuidv4 } from 'uuid';
import { ILoggedUser } from '../../../core/types/user';

export const getUser = () => {
  const string = localStorage.getItem('userSession');
  return string ? JSON.parse(string) : null;
};

export const generateHash = () => {
  return uuidv4();
};

const setUser = (user: ILoggedUser) => localStorage.setItem('userSession', JSON.stringify(user));

export const handleLogin = async (user: ILoggedUser) => {
  setUser(user);
  localStorage.setItem('staffSession', generateHash());
};

export const isLoggedIn = () => {
  const cookie = localStorage.getItem('staffSession');
  const user = getUser();
  return !!user && !!cookie;
};

export function logout() {
  localStorage.removeItem('userSession');
  localStorage.removeItem('staffSession');
}
