import ApiService from '../../../core/services/api.service';
import { ILoggedUser, ILoginUser } from '../../../core/types/user';

class LoginService extends ApiService {
  constructor() {
    super('/api/Login');
  }

  async login(user: ILoginUser) {
    return this.post<ILoggedUser>('', { ...user });
  }
  async logout() {
    return this.post('/Logout', {});
  }
}

const loginService = new LoginService();

export default loginService;
