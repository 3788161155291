import styled from '@emotion/styled';
import React from 'react';
import { Spin } from 'antd';

import useGlobalContext from '../core/stores/global/hook';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1001;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mask = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1002;
  height: 100%;
  background-color: #00000073;
`;

const LoadingScreen = () => {
  const globalContext = useGlobalContext();
  if (!globalContext.state.loading) {
    return null;
  }
  return (
    <Container>
      <Spin size="large" />
      <Mask />
    </Container>
  );
};
export default LoadingScreen;
